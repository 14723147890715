<template>
  <div>
    <b-row>
      <b-col cols="12" class="text-center mb-1">
        <span class="font-title" style="letter-spacing: 0.5rem; font-size: 2rem">
            湾厦中心室内外环境质量监测
        </span>
        <real-time-clock class="position-absolute" style="right: 1rem;top: 1.5rem"></real-time-clock>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12">
        <real-time-air-quality :title="'10层室内空气质量'" :vertical="false" style="height: calc((100vh - 16rem) / 3 + 1rem)"></real-time-air-quality>
      </b-col>

      <b-col cols="12">
        <hours48-params-temp :vertical="false" style="height: calc((100vh - 16rem) / 3 + 1rem)"></hours48-params-temp>
      </b-col>

      <b-col cols="6">
        <hours48-params-pm25 :vertical="false" style="height: calc((100vh - 16rem) / 3 + 1rem)"></hours48-params-pm25>
      </b-col>

      <b-col cols="6">
        <hours48-params-humi :vertical="false" style="height: calc((100vh - 16rem) / 3 + 1rem)"></hours48-params-humi>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {Column, Gauge, Pie,} from "@antv/g2plot";

export default {
  name: "Hjj2Demo1",
  components: {
    RealTimeAirQuality: () => import('@/views/front/hjj2/components/RealTimeAirQuality'),
    RealTimeClock: () => import('@/views/front/hjj2/components/RealTimeClock'),
    Hours48ParamsPm25: () => import('@/views/front/hjj2/components/Hours48ParamsPm25'),
    Hours48ParamsTemp: () => import('@/views/front/hjj2/components/Hours48ParamsTemp'),
    Hours48ParamsHumi: () => import('@/views/front/hjj2/components/Hours48ParamsHumi'),
  },
  methods: {
    chart_init_9() {
      const data = [
        {type: "2022-01-01", value: 27},
        {type: "2022-02-01", value: 15},
        {type: "2022-03-01", value: 25},
        {type: "2022-04-01", value: 35},
        {type: "2022-05-01", value: 27},
        {type: "2022-06-01", value: 15},
        {type: "2022-07-01", value: 25},
        {type: "2022-08-01", value: 35},
        {type: "2022-09-01", value: 27},
        {type: "2022-10-01", value: 15},
        {type: "2022-11-01", value: 25},
        {type: "2022-12-01", value: 35},
      ];

      const bar = new Column("chart_9", {
        data,
        xField: "type",
        yField: "value",
        xAxis: {
          label: {
            autoRotate: false,
            autoHide: true,
          },
        },
        yAxis: {
          grid: null,
        },
        //seriesField: "type",
        legend: false,
        label: {
          position: "top",
          style: {
            fill: "#808695",
            opacity: 0.8,
          },
        },
      });

      bar.render();
    },
    chart_init_10() {
      const data = [
        {type: "空调用电", value: 27},
        {type: "照明插座用电", value: 25},
        {type: "动力用电", value: 18},
        {type: "其它用电", value: 14},
      ];

      const piePlot = new Pie("chart_10", {
        appendPadding: 10,
        data,
        angleField: "value",
        colorField: "type",
        radius: 1,
        innerRadius: 0.6,
        pieStyle: {
          lineWidth: 0,
        },
        label: {
          type: "inner",
          offset: "-50%",
          content: "{value}",
          style: {
            textAlign: "center",
            fontSize: 14,
          },
        },
        interactions: [
          {type: "element-selected"},
          {type: "element-active"},
        ],
        statistic: {
          title: {
            formatter: () => "合计",
            offsetY: -8,
            style: {
              fontSize: "0.9rem",
              color: "",
            },
          },
          content: {
            offsetY: -4,
            style: {
              fontSize: "1.5rem",
              fontWeight: "normal",
              color: "",
            },
          },
        },
        legend: {
          itemName: {
            style: {
              fill: "#808695",
            },
          },
        },
      });

      piePlot.render();
    },
    chart_init_11() {
      const data = [
        {type: "2022-01-01", value: 27},
        {type: "2022-02-01", value: 15},
        {type: "2022-03-01", value: 25},
        {type: "2022-04-01", value: 35},
        {type: "2022-05-01", value: 27},
        {type: "2022-06-01", value: 15},
        {type: "2022-07-01", value: 25},
        {type: "2022-08-01", value: 35},
        {type: "2022-09-01", value: 27},
        {type: "2022-10-01", value: 15},
        {type: "2022-11-01", value: 25},
        {type: "2022-12-01", value: 35},
      ];

      const bar = new Column("chart_11", {
        data,
        xField: "type",
        yField: "value",
        xAxis: {
          label: {
            autoRotate: false,
            autoHide: true,
          },
        },
        yAxis: {
          grid: null,
        },
        //seriesField: "type",
        legend: false,
        label: {
          position: "top",
          style: {
            fill: "#808695",
            opacity: 0.8,
          },
        },
      });

      bar.render();
    },
    chart_init_12() {
      const gauge = new Gauge("chart_12", {
        percent: 0.86,
        type: "meter",
        innerRadius: 0.75,
        range: {
          ticks: [0, 1 / 3, 2 / 3, 1],
          color: ["#F4664A", "#FAAD14", "#30BF78"],
        },
        indicator: {
          pointer: {
            style: {
              stroke: "#D0D0D0",
            },
          },
          pin: {
            style: {
              stroke: "#D0D0D0",
            },
          },
        },
        statistic: {
          content: {
            offsetY: -10,
            style: {
              fontSize: "1.5rem",
              lineHeight: "1.5rem",
              color: "#28c76f",
            },
            formatter: () => "优",
          },
        },
      });
      gauge.render();
    },
  },
  mounted() {
    /* this.chart_init_9();
    this.chart_init_10();
    this.chart_init_11(); */
    // this.chart_init_12();
  },
};
</script>

<style scoped>
</style>